.footer_bg {
    width: 100%;
    background: #CCEDF9;
}

.footer_bg .footer_top {
    width: 100%;
    padding: 92px 0 36px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.footer_bg .footer_top .logo__footer_wrap {
    width: 100%;
    display: inline-block;
    position: relative;
}

.footer_bg .footer_top .logo__footer_wrap p {
    width: 100%;
    max-width: 452px;
    margin: 16px 0 0;
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: rgba(77, 89, 100, 0.7);
    line-height: 1.5;
}

.footer_bg .footer_top .wrap_links {
    width: 100%;
    max-width: 495px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.footer_bg .footer_top .wrap_links .link_block {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.footer_bg .footer_top .wrap_links .link_block li {
    width: 100%;
    display: inline-block;
    position: relative;
    margin-bottom: 24px;
}

.footer_bg .footer_top .wrap_links .link_block li:last-child {
    margin-bottom: 0;
}

.footer_bg .footer_top .wrap_links .link_block li a {
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    color: #25313C;
    text-decoration: none;
}

.footer_bg .footer_top .wrap_links .link_block li a:hover {
    text-decoration: underline;
}

.footer_bg .footer_bottom {
    width: 100%;
    text-align: center;
    padding: 16px 0;
}

.footer_bg .footer_bottom p {
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    font-weight: 300;
    color: rgba(77, 89, 100, 0.7);
    text-align: center;
    margin: 0;
}

@media screen and (max-width:1200px){
    .footer_bg .footer_top{
        padding: 92px 0px 36px;
    }
}

@media screen and (max-width:991px){
    .footer_bg .footer_top{
        padding: 92px 0px 36px;
    }
    .footer_bg .footer_top .logo__footer_wrap{
        max-width: 350px;
    }
    .footer_bg .footer_top .wrap_links{
        max-width: 300px;
    }
}

@media screen and (max-width:767px) {
    .footer_bg .footer_top {
        flex-direction: column;
        padding: 50px 0 30px;
    }
    .footer_bg .footer_top .logo__footer_wrap {
        margin-bottom: 25px;
    }
}