.headerSection {
    width: 100%;
    display: inline-block;
    position: relative;
}

.top_header {
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 24px 0 16px;
}

.overlay_bg {
    position: fixed;
    top: 72px;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 5;
    width: 100%;
    height: calc(100vh - 71px);
}

.top_header h6 {
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    font-weight: 300;
    text-align: left;
    color: var(--blueSapp);
    margin: 0 12px 0 0;
}

.top_header a {
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: var(--blueSapp2);
    display: flex;
    align-items: center;
    text-decoration: none;
}

.top_header a span {
    margin-left: 8px;
}

.main_header {
    width: 100%;
    /* display: inline-block; */
    position: sticky;
    background: transparent;
    padding-bottom: 15px;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    transition: 0.3s linear;
}

.main_header.sticky {
    padding: 15px;
    position: fixed;
    top: 0;
    left: 0;
    background: #FFF;
    z-index: 100;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
}

.main_header .navBar_wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main_header.watch_navbar {
    padding: 15px 0;
}

.main_header .navBar_wrap .logo_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main_header .navBar_wrap .logo_wrap.watch_logo_wrap {
    justify-content: center;
    width: 100%;
}

.main_header .navBar_wrap .logo_wrap .navbar_toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem;
    display: none;
}

.main_header .navBar_wrap .logo_wrap .navbar_toggler .icon_toggler {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: url('../../../images/toggle-icon.svg') no-repeat center center;
    background-size: 100% 100%;
}

.main_header .navBar_wrap .navbar_collapse {
    /* width: 100%; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.main_header .navBar_wrap .navbar_collapse.active {
    display: flex;
}

.main_header .navBar_wrap .navbar_collapse .navbar_nav {
    width: 100%;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    list-style: none;
    padding-left: 28px;
    margin: 0;
}

.main_header .navBar_wrap .navbar_collapse .navbar_nav li {
    margin: 0px 24px 0 0;
}

.main_header .navBar_wrap .navbar_collapse .navbar_nav li:last-child {
    margin: 0 0;
}

.main_header .navBar_wrap .navbar_collapse .navbar_nav li a {
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #25313C;
    display: inline-block;
    position: relative;
    text-decoration: none;
}

.main_header .navBar_wrap .navbar_collapse .navbar_nav li.btns {
    margin: 0 0;
}

.main_header .navBar_wrap .navbar_collapse .navbar_nav li.btns .btn_nav {
    padding: 17px 37px;
    border: 1px solid #FCAF17;
    border-radius: 4px;
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
}

.main_header .navBar_wrap .navbar_collapse .navbar_nav li.btns .btn_nav.btn_outline_demo {
    background: transparent;
    color: #FCAF17;
    margin: 0 20px 0 20px;
}

.main_header .navBar_wrap .navbar_collapse .navbar_nav li.btns .btn_nav.btn_outline_demo:hover {
    background: rgba(252 175 23 / 5%);
}

.main_header .navBar_wrap .navbar_collapse .navbar_nav li.btns .btn_nav.btn_yellow {
    background: #FCAF17;
    color: #FFF;
}

.main_header .navBar_wrap .navbar_collapse .navbar_nav li.btns .btn_nav.btn_yellow:hover {
    background: rgba(252 175 23 / 85%);
}

.main_header .navBar_wrap .navbar_collapse .navbar_nav li a.active {
    color: #FCAF17;
}

@media screen and (max-width:1200px) {
    .headerSection {
        padding: 0 15px;
    }
    .main_header .navBar_wrap .navbar_collapse .navbar_nav {
        /* padding-left: 15px; */
        align-items: center;
        justify-content: flex-end;
    }
    .main_header .navBar_wrap .navbar_collapse .navbar_nav li {
        margin: 0 15px 0 0;
    }
    .main_header .navBar_wrap .navbar_collapse .navbar_nav li.btns .btn_nav {
        padding: 15px;
    }
    .main_header .navBar_wrap .navbar_collapse .navbar_nav li.btns .btn_nav.btn_outline_demo {
        margin: 0 18px;
    }
}

@media screen and (max-width:1028px) {
    .headerSection {
        padding: 0 15px;
    }
    .main_header .navBar_wrap .navbar_collapse .navbar_nav {
        /* padding-left: 15px; */
        align-items: center;
        justify-content: flex-end;
    }
    .main_header .navBar_wrap .navbar_collapse .navbar_nav li {
        margin: 0 10px 0 0;
    }
    .main_header .navBar_wrap .navbar_collapse .navbar_nav li.btns .btn_nav {
        padding: 15px;
    }
    .main_header .navBar_wrap .navbar_collapse .navbar_nav li.btns .btn_nav.btn_outline_demo {
        margin: 0 10px;
    }
}

@media screen and (max-width:991px) {
    .headerSection {
        padding: 0;
    }
    .top_header {
        justify-content: flex-start;
        padding: 15px 0 15px;
    }
    .top_header h6 {
        font-size: 14px;
        margin-right: 12px;
    }
    .top_header a {
        font-size: 14px;
    }
    /* .main_header{
        padding: 0 15px 15px;
    } */
    .main_header.sticky {
        padding: 15px 0;
    }
    .main_header.isPadd {
        padding: 15px 0;
        /* background: #FFF; */
    }
    /* .main_header .navBar_wrap .navbar_collapse {
        display: none;
    } */
    .main_header .navBar_wrap .logo_wrap {
        width: 100%;
    }
    .main_header .navBar_wrap .logo_wrap .navbar_toggler {
        display: block;
    }
    .main_header .navBar_wrap .navbar_collapse {
        width: 100%;
        max-width: 275px;
        height: calc(100vh - 71px);
        position: fixed;
        top: 71px;
        left: -350px;
        background: #FFF;
        z-index: 99;
        overflow: hidden;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.04);
        overflow-y: auto;
        transition: left 0.3s linear;
    }
    .main_header .navBar_wrap .navbar_collapse.active {
        left: 0;
    }
    .main_header .navBar_wrap .navbar_collapse {
        justify-content: flex-start;
        align-items: flex-start;
    }
    .main_header .navBar_wrap .navbar_collapse .navbar_nav {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 0;
        padding: 10px 0px;
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
    }
    .main_header .navBar_wrap .navbar_collapse .navbar_nav li {
        margin-right: 0;
        width: 100%;
    }
    .main_header .navBar_wrap .navbar_collapse .navbar_nav li a {
        padding: 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.10);
        width: 100%;
        /* margin-bottom: 15px; */
    }
    .main_header .navBar_wrap .navbar_collapse .navbar_nav li.btns .btn_nav {
        padding: 15px;
    }
    .main_header .navBar_wrap .navbar_collapse .navbar_nav li.btns .btn_nav.btn_outline_demo {
        margin: 0;
        border: 0;
        color: #25313C;
        border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    }
    .main_header .navBar_wrap .navbar_collapse .navbar_nav li.btns .btn_nav.btn_yellow {
        margin: 0;
        border: 0;
        color: #25313C;
        background: transparent;
    }
}

/* @media screen  and (max-width:576px){
    
} */

@media screen and (max-width:320px) {
    .top_header h6 {
        font-size: 12px;
        margin-right: 12px;
    }
    .top_header a {
        font-size: 12px;
    }
}