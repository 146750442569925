.bannerSection {
    width: 100%;
    padding: 108px 0 127px;
}

.banner_img_wrap {
    width: 100%;
    height: 841px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    /* clip-path: polygon(100% 0, 100% 83%, 46% 100%, 0 100%, 0 0); */
    /* clip-path: polygon(100% 0%, 100% 83%, 46% 100%, 0 100%, 0% 0%); */
    /* clip-path: inset(15px 20px 50px round 5px); */
    /* clip-path: polygon(100% 0%, 100% 80%, 21% 100%, 0% 97%,  0% 0%); */
    /* background:linear-gradient(#FFF1D7 0%, #FCAF17 100%) */
    background: url('../../images/bg-hero-banner2.svg') no-repeat top center;
    background-size: 100%;
    /* object-fit: cover; */
}

/* .banner_img_wrap img{
    width: 100vw;
} */

/* @supports (clip-path: inset(50% round 50px)){
    .banner_img_wrap{
        width: 100%;
        height: 841px;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
        /* clip-path: polygon(100% 0, 100% 83%, 46% 100%, 0 100%, 0 0); */

/* clip-path: polygon(100% 0%, 100% 83%, 46% 100%, 0 100%, 0% 0%); */

/* clip-path: inset(15px 20px 50px round 5px); */

/* clip-path: polygon(100% 0%, 100% 70%, 20% 100%, 0% 94%,  0% 0%);
        background:linear-gradient(#FFF1D7 0%, #FCAF17 100%) */

/* background: url('../public/images/bg-hero-banner.svg') no-repeat top;
        background-size: cover;
        object-fit: fill;
    }
} */

.banner_wrap {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.banner_wrap .banner_content_wrap {
    width: 100%;
    max-width: 630px;
    display: inline-block;
    /* position: relative; */
}

.banner_wrap .hero_img_wrap {
    width: 100%;
    max-width: 502px;
}

.banner_wrap .banner_content_wrap h1 {
    font-family: 'Rubik', sans-serif;
    font-size: 48px;
    font-weight: 500;
    line-height: 57px;
    color: #1B1B1B;
    margin: 0px 0 24px;
}

.banner_wrap .banner_content_wrap .review_wrap {
    width: 100%;
    display: inline-block;
    /* position: relative; */
    margin-bottom: 16px;
}

.banner_wrap .banner_content_wrap .review_wrap .rating_wrap {
    width: 100%;
    max-width: 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.banner_wrap .banner_content_wrap .review_wrap .rating_wrap span {
    width: 24px;
    /* height: 24px; */
    display: inline-block;
    /* position: relative; */
    /* background: url('../../images/star-icon.svg') no-repeat center center; */
}

.banner_wrap .banner_content_wrap .review_wrap p {
    font-size: 14px;
    font-family: 'Rubik', sans-serif;
    font-weight: 300;
    line-height: 20px;
    color: #4D5964;
    margin: 7px 0 10px;
    max-width: 390px;
}

.banner_wrap .banner_content_wrap .review_wrap p span {
    display: block;
}

.banner_wrap .banner_content_wrap .text_banner {
    font-size: 18px;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    line-height: 26px;
    color: #4D5964;
    margin: 0px 0 32px;
}

.btn_demoVideo {
    padding: 17px 62px;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    border-radius: 4px;
    display: inline-block;
    text-decoration: none;
}

.btn_demoVideo.btnBg_white {
    background: #FFF;
    color: #FCAF17;
}

.trust_wrap {
    width: 100%;
    padding: 0px 0 96px;
}

.subTitle {
    font-size: 32px;
    font-weight: 500;
    width: 100%;
    text-align: center;
    color: #25313C;
    margin: 0;
    line-height: 38px;
}

.subTitle span {
    display: block;
    color: #006388;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 300;
}

.subTitle.textLeft, .subTitle.textLeft span {
    text-align: left;
}

.max_357 {
    max-width: 357px;
}

.subTitle2 {
    font-size: 40px;
    font-weight: 500;
    width: 100%;
    text-align: center;
    color: #25313C;
    margin: 0;
    line-height: 48px;
}

.subTitle2_text {
    font-size: 20px;
    font-weight: 400;
    width: 100%;
    text-align: center;
    color: #4D5964;
    margin: 16px 0 0;
    line-height: 24px;
}

.trust_ul {
    width: 100%;
    max-width: 992px;
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    padding-left: 0;
    list-style: none;
    margin: 64px auto 0;
}

.pricing_wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 72px;
}

.pricing_wrap .plan_div {
    width: 100%;
    max-width: 360px;
    height: 493px;
    padding: 25px 77px;
    border-radius: 10px;
    background: #EDF9FD;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.3s linear;
}

.pricing_wrap .plan_div .plan_title {
    font-size: 40px;
    line-height: 48px;
    font-weight: 500;
    text-align: center;
    color: #25313C;
    margin: 0;
    font-family: 'Libre Franklin', sans-serif;
}

.pricing_wrap .plan_div .plan_price {
    width: 100%;
    text-align: center;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    color: #25313C;
    font-size: 58px;
    line-height: 66px;
    margin: 56px 0 40px;
}

.pricing_wrap .plan_div .plan_price span {
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 46px;
    color: #6D7D8B;
    display: block;
}

.btn_plan {
    padding: 13px 32px;
    border-radius: 4px;
    border: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 36px;
    letter-spacing: 1.5%;
    background: #006388;
    color: #FFF;
    cursor: pointer;
}

.pricing_wrap .plan_div.active {
    background: #00A5E0;
}

.pricing_wrap .plan_div.active .plan_title, .pricing_wrap .plan_div.active .plan_price, .pricing_wrap .plan_div.active .plan_price span {
    color: #FFF;
}

.pricing_wrap .plan_div.active .btn_plan {
    background: #FFF;
    color: #00A5E0;
}

.btn_plan:hover, .pricing_wrap .plan_div.active .btn_plan:hover {
    background: #25313C;
    color: #FFF;
}

.pricing_wrap .plan_div:hover, .pricing_wrap .plan_div.active:hover {
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.15);
    /* box-shadow: 0 3px 5px rgb(237 249 253 / 50%); */
}

/* CAT Section */

.CTASection {
    width: 100%;
    padding: 60px 0;
    background-size: cover;
}

.cta_wrap {
    width: 100%;
    max-width: 605px;
    display: inline-block;
    /* flex-direction: column; */
}

.cta_wrap h2 {
    font-family: "Libre Franklin", sans-serif;
    font-weight: 700;
    font-size: 32px;
    color: #FFF;
    line-height: 39px;
    text-align: left;
    margin: 0;
}

.cta_wrap p {
    font-family: "Libre Franklin", sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: #FFF;
    line-height: 26px;
    text-align: left;
    margin: 24px 0 32px;
}

.cta_wrap .btn_tryNow {
    padding: 17px 45px;
    display: inline-block;
    text-align: center;
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #FFF;
    line-height: 22px;
    margin: 0;
    background: #FCAF17;
    border: 0;
    border-radius: 4px;
    text-decoration: none;
}

.cta_wrap .btn_tryNow:hover {
    background: #FFF;
    color: #25313C;
}

/* CAT Section over */

/* features component */

.feature_section {
    width: 100%;
    padding: 120px 0;
    position: relative;
}

.feature_section::after {
    content: '';
    width: 691px;
    height: 584px;
    display: inline-block;
    background-image: url('../../images/feature-shape2.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top right;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0%, -40%);
    z-index: -1;
}

.feature_wrap {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 80px;
    position: relative;
    list-style: none;
}

.feature_wrap .feature_block {
    width: 100%;
    max-width: 263px;
    min-height: 463px;
    padding: 32px 20px 25px;
    display: inline-block;
    position: relative;
    background: #FFFFFF;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
}

.feature_wrap .feature_block:hover {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04), 0px 5px 15px rgba(0, 0, 0, 0.06), 0px 4px 12px rgba(0, 0, 0, 0.04);
}

.feature_wrap .feature_block .img_wrp {
    width: 100%;
    max-width: 95px;
    height: 94px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    justify-content: flex-start;
}

.feature_wrap .feature_block .img_wrp img {
    width: 100%;
    object-fit: cover;
}

.feature_wrap .feature_block h4 {
    font-family: 'Libre Franklin', sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #25313C;
    margin: 24px 0 16px;
}

.feature_wrap .feature_block p {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    color: #4D5964;
    margin: 0;
}

/* features component over */

/* cloud section */

.cloud_section {
    width: 100%;
}

.cloud_wrap {
    width: 100%;
    max-width: 945px;
    margin: 0 auto;
}

.max_522 {
    max-width: 522px;
    margin-left: auto;
    margin-right: auto;
}

.max_732 {
    max-width: 732px;
    margin-left: auto;
    margin-right: auto;
}

.cloud_wrap p {
    font-size: 18px;
    color: #4D5964;
    line-height: 26px;
    text-align: center;
}

.cloud_wrap .image_cloud {
    width: 100%;
    max-width: 945px;
    margin: 64px auto 0;
}

.cloud_wrap .image_cloud img {
    width: 100%;
}

/* cloud section over */

/* help section */

.help_section {
    width: 100%;
    padding: 268px 0 72px;
    background: #F6FDFF;
    margin-top: -148px;
}

.help_wrap {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.help_wrap .help_block {
    width: 100%;
    display: inline-block;
    position: relative;
}

.help_wrap .help_block.max_357 {
    max-width: 357px;
}

.help_list {
    width: 100%;
    max-width: 490px;
    display: inline-block;
    position: relative;
    padding-left: 0;
    margin: 48px 0 0;
    list-style: none;
}

.help_list li {
    padding-left: 54px;
    width: 100%;
    margin-bottom: 40px;
    position: relative;
    display: inline-block;
}

.help_list li:last-child {
    margin-bottom: 0;
}

.help_list li::after {
    content: url('../../images/help-li-icon.svg');
    position: absolute;
    top: 0;
    left: 0;
}

.help_list li h5 {
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #25313C;
    margin: 0 0 12px;
}

.help_list li p {
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #4D5964;
    margin: 0;
}

.help_list li p.limit {
    display: -webkit-box;
    display: -moz-box;
    display: -o-box;
    display: -ms-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    -moz-line-clamp: 3;
    -o-line-clamp: 3;
    -o-box-orient: vertical;
    -ms-line-clamp: 3;
    -ms-box-orient: vertical;
}

.help_block_img {
    width: 100%;
    max-width: 698px;
    display: inline-block;
    position: relative;
    z-index: 0;
}

.help_block_img .img_wrap::after {
    content: url('./../../images/dots-shape.svg');
    position: absolute;
    right: -46px;
    top: -5px;
    z-index: -1;
}

.help_block_img .img_wrap {
    width: 100%;
    max-width: 457px;
    max-height: 553px;
    margin-left: auto;
    margin-top: 64px;
    z-index: 2;
}

.help_block_img .img_wrap img {
    width: 100%;
    z-index: 2;
}

.review_ul {
    width: 100%;
    max-width: 456px;
    padding-left: 0;
    /* margin: -560px 0 0; */
    position: absolute;
    z-index: 3;
    top: 180px;
    left: 0;
}

.review_ul li {
    width: 100%;
    max-width: 358px;
    margin-bottom: 37px;
    padding: 22px 25px;
    display: block;
    position: relative;
    margin-left: auto;
    background: #FDB72D;
    border-radius: 8px;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
}

.review_ul li:last-child {
    margin-bottom: 0;
}

.review_ul li:nth-child(even) {
    margin-left: unset;
}

.review_ul li p {
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #FFF;
    margin: 0;
}

/* start section */

.start_section {
    width: 100%;
    display: inline-block;
    position: relative;
    padding: 96px 0 91px;
    background: #006388;
}

.start_section::before {
    content: url('../../images/round-shape.svg');
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -53%);
}

.start_section::after {
    content: url('../../images/lines-shape.svg');
    position: absolute;
    right: 15%;
    top: 0%;
    transform: translate(-15%, 0%);
}

.start_wrap {
    width: 100%;
    max-width: 565px;
    margin: 0 auto;
    text-align: center;
}

.start_wrap h5 {
    font-family: 'Libre Franklin';
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    font-weight: 700;
    color: #FFF;
    margin: 0;
}

.start_wrap p {
    font-family: 'Libre Franklin';
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    font-weight: 400;
    color: #FFF;
    margin: 24px 0 40px;
}

.start_wrap .btn_start {
    padding: 17px 45px;
    background: #FCAF17;
    border: 0;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    display: inline-block;
    color: #FFF;
    text-decoration: none;
}

/* start section over */

/* help section over */

@media screen and (max-width:1440px) {
    .banner_img_wrap {
        background: url('../../images/bg-hero-banner.svg') no-repeat top;
    }
    .feature_section {
        overflow: hidden;
    }
    .feature_section::after {
        width: 691px;
        height: 584px;
        transform: translate(8%, -40%);
        overflow: hidden;
        background-position: fixed;
    }
}

/* @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    /* styles for Retina-type displays *
    .banner_img_wrap {
        background: url('../public/images/bg-hero-banner.svg') no-repeat top center;
    }
} */

@media screen and (max-width:1200px) {
    .CTASection {
        padding: 60px 0px;
    }
    .help_block_img .img_wrap::after {
        right: 0;
    }
    .feature_wrap {
        padding: 0 15px;
    }
    .max_522 {
        max-width: 640px;
    }
    .banner_wrap .banner_content_wrap {
        max-width: 430px;
    }
    .banner_wrap .banner_content_wrap .text_banner {
        display: -webkit-box;
        display: -moz-box;
        display: -o-box;
        display: -ms-box;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        -moz-line-clamp: 3;
        -o-line-clamp: 3;
        -o-box-orient: vertical;
        -ms-line-clamp: 3;
        -ms-box-orient: vertical;
    }
}

@media screen and (max-width:1024px) {
    .help_block_img .img_wrap::after {
        right: 0;
    }
    .help_block_img {
        max-width: 568px;
    }
    .feature_wrap {
        padding: 80px 0px 0;
    }
    .feature_wrap li {
        padding: 0 5px;
    }
    .feature_wrap li .feature_block {
        min-height: 450px;
    }
    .banner_wrap .banner_content_wrap {
        max-width: 430px;
    }
    .banner_wrap .banner_content_wrap .text_banner {
        display: -webkit-box;
        display: -moz-box;
        display: -o-box;
        display: -ms-box;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        -moz-line-clamp: 3;
        -o-line-clamp: 3;
        -o-box-orient: vertical;
        -ms-line-clamp: 3;
        -ms-box-orient: vertical;
    }
}

@media screen and (max-width:991px) {
    .pricing_wrap .plan_div {
        max-width: 235px;
        height: 395px;
        padding: 25px 15px;
    }
    .pricing_wrap .plan_div .plan_title {
        font-size: 30px;
        line-height: 38px;
    }
    .pricing_wrap .plan_div .plan_price {
        font-size: 38px;
        line-height: 58px;
    }
    .feature_wrap {
        display: inline-block;
        padding: 80px 0px 0;
    }
    .feature_wrap li {
        margin: 0 auto 25px;
        width: calc(100% / 2);
        float: left;
    }
    .feature_wrap li .feature_block {
        margin: 0 auto;
        display: block;
        max-width: 320px;
    }
    .help_wrap {
        flex-direction: column;
    }
    .hero_img_wrap img {
        width: 100%;
    }
    .bannerSection {
        padding: 50px 0 100px;
    }
    .banner_img_wrap {
        height: 685px;
        background-size: cover;
    }
    .banner_wrap .banner_content_wrap h1 {
        font-size: 30px;
        line-height: 38px;
    }
}

@media screen and (max-width:767px) {
    .banner_wrap {
        flex-direction: column;
    }
    .bannerSection {
        padding: 25px 0 50px;
    }
    .banner_wrap .banner_content_wrap {
        /* padding: 0 15px; */
        margin-bottom: 25px;
    }
    .banner_wrap .banner_content_wrap h1 {
        font-size: 35px;
        line-height: 45px;
        /* text-align: center; */
    }
    .hero_img_wrap img {
        width: 100%;
    }
    .trust_ul li img {
        width: 80px;
    }
    .pricing_wrap {
        flex-direction: column;
    }
    .pricing_wrap .plan_div {
        margin-bottom: 20px;
        padding: 25px;
    }
    .help_wrap {
        flex-direction: column;
    }
    .help_block_img .img_wrap::after {
        right: -0px;
        top: 25px;
    }
    .start_section::before {
        left: 0;
        top: 82%;
        transform: translate(0, -45%);
        z-index: 0;
    }
    .start_section::after {
        right: 17%;
        top: -6%;
        transform: translate(30%, 0%);
    }
    .start_wrap {
        z-index: 1;
    }
    .cta_wrap {
        text-align: center;
    }
    .cta_wrap h2 {
        font-size: 25px;
        text-align: center;
    }
    .cta_wrap p {
        font-size: 16px;
        text-align: center;
    }
    .feature_wrap {
        display: inline-block;
        padding: 80px 0px 0;
    }
    .feature_wrap li {
        margin: 0 auto 25px;
        width: calc(100% / 2);
        float: left;
    }
    .feature_wrap li .feature_block {
        margin: 0 auto 25px;
        width: 100%;
    }
    .feature_wrap li:nth-child(even) {
        margin-left: auto;
        float: right;
    }
    .subTitle2 {
        font-size: 32px;
    }
    .cloud_wrap .subTitle2_text {
        font-size: 16px;
    }
}

@media screen and (max-width:576px) {
    .banner_wrap {
        flex-direction: column;
    }
    .bannerSection {
        padding: 25px 0 50px;
    }
    .banner_wrap .banner_content_wrap {
        margin-bottom: 25px;
    }
    .banner_wrap .banner_content_wrap h1 {
        font-size: 25px;
        line-height: 30px;
    }
    .hero_img_wrap img {
        width: 100%;
    }
    .trust_ul {
        flex-direction: column;
        max-width: 100%;
    }
    .trust_ul li {
        width: inherit;
        /* float: left; */
        /* text-align: center; */
    }
    .pricing_wrap {
        flex-direction: column;
    }
    .pricing_wrap .plan_div {
        margin-bottom: 20px;
        padding: 25px;
        max-width: 100%;
    }
    .feature_wrap {
        flex-direction: column;
    }
    .feature_wrap .feature_block {
        margin-bottom: 20px;
    }
    .help_wrap {
        flex-direction: column;
    }
    .help_block_img .img_wrap::after {
        right: -0px;
        top: 25px;
    }
    .start_section::before {
        left: 0;
        top: 82%;
        transform: translate(0, -45%);
        z-index: 0;
    }
    .start_section::after {
        right: 17%;
        top: -6%;
        transform: translate(30%, 0%);
    }
    .start_wrap {
        z-index: 1;
    }
    .cta_wrap {
        text-align: center;
    }
    .cta_wrap h2 {
        font-size: 25px;
        text-align: center;
    }
    .cta_wrap p {
        font-size: 16px;
        text-align: center;
    }
    .feature_wrap li {
        width: 100%;
        display: block;
    }
    .feature_wrap li .feature_block {
        max-width: 100%;
    }
    .subTitle2 {
        font-size: 32px;
    }
    .cloud_wrap .subTitle2_text {
        font-size: 16px;
    }
    .banner_wrap .banner_content_wrap {
        max-width: 100%;
    }
    .banner_wrap .banner_content_wrap .text_banner {
        display: -webkit-box;
        display: -moz-box;
        display: -o-box;
        display: -ms-box;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        -moz-line-clamp: 3;
        -o-line-clamp: 3;
        -o-box-orient: vertical;
        -ms-line-clamp: 3;
        -ms-box-orient: vertical;
    }
}